.image-logo {
  border-radius: 50%;
  height: 2.2em;
  width: 2.2em;
  margin: 5px 10px;
}

.popover {
  /*  display: none;*/
}

.chip-hidden {
  display: none;
}
