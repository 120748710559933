.side-bar {
  max-width: 15.8125rem;
  width: inherit;
  height: calc(100vh);
  color: #fff;
  position: fixed;
  top: 5rem;
  z-index: 2;
  white-space: pre-line;
}

.side-bar .close {
  display: none;
}

.side-bar .look-ups {
  font-weight: 400;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 1.25rem;
}
.side-bar .look-ups svg {
  color: #7eb6f7;
}

.side-bar ul {
  list-style-type: none;
}

.side-bar ul li .btn {
  display: flex;
  text-align: left;
  color: #fff;
  background: #185195;
  padding: 0.75rem;
  width: 100%;
  margin-bottom: 1.25rem;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.5rem;
  overflow: hidden;
  min-height: 3.75rem;
}

.side-bar ul li .btn .icon {
  width: 2.25rem;
  height: 2.25rem;
  min-width: 2.25rem;
  background-color: #2463ae;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}
.side-bar .collapse-btn {
  background: #185195;
  width: 3rem;
  height: 3rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  position: absolute;
  top: calc(100vh - 10rem);
  right: 0.25rem;
}

@keyframes animateBottom {
  from {
    bottom: -15rem;
    opacity: 0;
    top: unset;
  }

  to {
    bottom: 0;
    opacity: 1;
    top: unset;
  }
}

@media screen and (max-width: 800px) {
  .side-bar {
    max-width: unset;
    height: 21.5rem;
    top: unset;
    bottom: 0;
  }

  .side-bar .close {
    display: inline;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #fff;
  }

  .side-bar .look-ups {
    justify-content: center;
  }

  .side-bar .look-ups svg {
    display: none;
  }

  .side-bar ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    gap: 1rem;
  }

  .side-bar ul li .btn {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
    height: 100%;
    padding: 0.75rem 0.5rem;
  }
}

.selectedIcons {
  background: #ecf4fe;
}

.smallestText {
  font-size: 9px;
}

.submenu-sidebar {
  padding-top: 0.5rem;
  padding-bottom: 0, 5rem;
}
